<template>
  <v-container>
    <v-row justify="center">
      <v-card width="600" outlined>
        <v-list-item two-line>
          <v-list-item-avatar size="80">
            <v-icon size="60">mdi-account-circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">{{ userName }}</v-list-item-title>
            <v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions v-if="!isAdmin">
          <v-spacer />
          <v-btn color="primary" @click="resetPassword" depressed outlined>{{ $tc('resetPassword') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  data() {
    return {
      pending: false
    };
  },

  computed: mapGetters(['userEmail', 'userName', 'isAdmin']),

  methods: {
    async resetPassword() {
      try {
        const result = await this.$auth.changePassword(this.userEmail);
        this.alertSuccess(result);
      } catch (error) {
        this.alertError(error);
      }
    }
  }
};
</script>script
